import { Injectable, InjectionToken, Injector, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AppConsts } from '../AppConsts';
import { isPlatformBrowser } from '@angular/common';
import { FailedReasonType } from './services/MahdBuraq.Utility.Models/FailedReasonType';
import { AppSessionService } from 'src/app/core/services/app/app-session.service';
import { environment } from 'src/environments/environment';

export const API_BASE_URL_SGO = new InjectionToken<string>('API_BASE_URL_SGO');

export const verUrls = {
  domain: {
    AuthApiServices: '/v1/auth/',
    SaleApiServices: '/v1/sale/',
    FlightApiServices: '/v1/flight/',
    HotelApiServices: '/v1/hotel/',
    TrainApiServices: '/v1/train/',
    GatewayService: '/v1/',
    BusServices: '/v1/bus/',
  },
  staging: {
    AuthApiServices: '/v1/auth/',
    SaleApiServices: '/v1/sale/',
    FlightApiServices: '/v1/flight/',
    HotelApiServices: '/v1/hotel/',
    TrainApiServices: '/v1/train/',
    GatewayService: '/v1/',
    BusServices: '/v1/bus/',
  },
  localhost: {
    AuthApiServices: '/v1/auth/',
    SaleApiServices: '/v1/sale/',
    FlightApiServices: '/v1/flight/',
    HotelApiServices: '/v1/hotel/',
    TrainApiServices: '/v1/train/',
    GatewayService: '/v1/',
    BusServices: '/v1/bus/',
  },
};

export const environmentServerUrl = environment.environmentServerUrl;

export function getDateParser(key: any, value: string) {
  if (typeof value === 'string') {
    if (isDate(value) == true) {
      return new Date(Date.parse(value));
    }
  }
  return value;
}

export function isDate(value) {
  if (!value || typeof value != 'string') return false;
  else if ((value.indexOf('/') == -1 && value.indexOf('-') == -1) || value.indexOf('http') >= 0) return false;
  let isNumer = /^-?\d+$/.test(value);
  return (
    isNumer != true &&
    new Date(value).toString() !== 'Invalid Date' &&
    value.match(/^([-0-9:\.\/ pmgmtPMGMTzZ]*)$/) !== null
  );
}

@Injectable({
  providedIn: 'any',
})
export class ServerConnectionService {
  public environmentServerUrl: string;
  platformId: any;
  appSessionService: AppSessionService;
  constructor(public injector: Injector, public http: HttpClient) {
    this.appSessionService = injector.get(AppSessionService);
    this.platformId = injector.get(PLATFORM_ID);

    //this.environmentServerUrl = environmentServerUrl;
    //if (this.isUtravs)
    {
      this.environmentServerUrl = this.getDomain;
    }
    AppConsts.remoteServiceBaseUrlSGO = this.environmentServerUrl;
  }
  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  get getDomain(): string {
    if (this.isBrowser) {
      if (location.href.toLowerCase().indexOf(':4200') > -1) return 'localhost';
    }
    return environment.environmentServerUrl;
  }

  postFile<T>(url: string, serviceName: string, ...params: any[]): Observable<T> {
    return new Observable<T>(observer => {
      var formData = new FormData();
      var current = this;
      //first add parameters
      params.forEach(function (element, index, array) {
        //console.log(element, index, array.length);
        if (!(element instanceof Array && element[0] instanceof File)) {
          if (index != array.length - 1) {
            var data = element.Value;
            try {
              current.fixDateTimeProperties(data, []);
            } catch (ex) {
              //console.log("fix date time error:", ex);
            }

            formData.append(element.Name, JSON.stringify(data));
          }
        }
      });

      //second add files
      params.forEach(function (element, index, array) {
        if (element instanceof Array && element[0] instanceof File) {
          //console.log(element, index, array.length);
          //if (index != array.length - 1) console.log("append file:", element);
          formData.append('file', element[0]);
        }
      });

      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      this.http
        .post(this.checkUrl(serviceName) + url, formData, {
          headers: headers,
          withCredentials: true,
        })
        .subscribe({
          next: (response: any) => {
            try {
              var json = JSON.stringify(response);
              var result = JSON.parse(json, getDateParser);
              result = this.deserializeReferences(result, {}, []);
              this.assignNotExistValues(result, params[params.length - 1], []);
              observer.next(result);
            } catch (ex) {
              observer.error(ex);
              //console.log("fix reference error:", ex);
            }
          },
          error: error => {
            //console.log("error response: ", error);
          },
        });
    });
  }

  postFileWithData<T>(url: string, serviceName: string, fileData: any, ...params: any[]): Observable<T> {
    return new Observable<T>(observer => {
      var formData = new FormData();
      // var input = event.target;
      params.forEach(element => {
        formData.append(element.Name, element.Value);
      });
      formData.append('userfile', fileData);
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      this.http
        .post(this.checkUrl(serviceName) + url, formData, {
          headers: headers,
          withCredentials: true,
        })
        .subscribe({
          next: (response: any) => {
            try {
              var json = JSON.stringify(response);
              var result = JSON.parse(json, getDateParser);
              result = this.deserializeReferences(result, {}, []);
              observer.next(result);
            } catch (ex) {
              observer.error(ex);
              //console.log("fix reference error:", ex);
            }
          },
          error: error => {
            //console.log("error response: ", error);
          },
        });
    });
  }

  get<T>(url: string, data: any, typeSafe: any, serviceName?: string): Observable<T> {
    try {
      this.fixDateTimeProperties(data, []);
    } catch (ex) {
      //console.log("fix date time error:", ex);
    }
    let queryParamsString = '';
    if (data) {
      let params = new HttpParams({ fromObject: data });
      queryParamsString = '?' + params.toString();
    }
    return new Observable<T>(observer => {
      return this.http.get(this.checkUrl(serviceName) + url + queryParamsString).subscribe({
        next: response => {
          try {
            var json = JSON.stringify(response);
            var result = JSON.parse(json, getDateParser);
            result = this.deserializeReferences(result, {}, []);
            //asine null values from server to object of client
            this.assignNotExistValues(result, typeSafe, []);
            if (result.FailedReason == FailedReasonType.SessionAccessDenied) {
              //retry
            }
            observer.next(result);

            observer.complete();
          } catch (ex) {
            //console.log("fix reference error:", ex);
          }
        },
        error: error => {
          //console.log("error response: ", error);
          observer.next(error);
        },
      });
    });
  }

  checkUrl(serviceName) {
    let url = 'utravs.com'; // Get the domain from the URL
    let protocol = 'https:';
    let api = 'api';
    let proxy = '';
    let baseurl = '';
    if (this.isBrowser) {
      url = location.host
        .replace('agent.', '')
        .replace('agent-mata.', '')
        .replace('agent-sale.', '')
        .replace('mata.', '');
      if (url.indexOf('localhost') > -1) {
        proxy = '/' + api;
        api = '';
      }
      protocol = location.protocol;
      if (api) {
        if (url.split('.').length > 2) api = `${api}-`;
        else api = `${api}.`;
      }
      baseurl = `${protocol}//${api}${url}${proxy}${verUrls[environment.environmentServerUrl][serviceName]}`;
    } else {
      if (environment.environmentServerUrl == 'staging') api = `${api}-beta.`;
      else api = `${api}.`;
      baseurl = `${protocol}//${api}${url}${proxy}${verUrls[environment.environmentServerUrl][serviceName]}`;
    }
    return baseurl;
  }

  post<T>(url: string, data: any, typeSafe: any, serviceName?: string): Observable<T> {
    //console.log(url,data,typeSafe,serviceName);
    try {
      this.fixDateTimeProperties(data, []);
    } catch (ex) {
      //console.log("fix date time error:", ex);
    }

    return this.returnRetryObeservable<T>(serviceName, url, data, getDateParser, typeSafe, 0);
  }
  returnRetryObeservable<T>(serviceName, url, data, dateParser, typeSafe, retCount = 0) {
    let res = new Subject<T>();
    //console.log(url,data,typeSafe,serviceName);
    this.http
      .post(this.checkUrl(serviceName) + url, data, {
        withCredentials: true,
        headers: { 'ngsw-bypass': 'true' },
      })
      .subscribe({
        next: (response: any) => {
          try {
            //console.log('subscribe',response);
            var json = JSON.stringify(response);
            var result = JSON.parse(json, dateParser);
            result = this.deserializeReferences(result, {}, []);
            //asine null values from server to object of client
            this.assignNotExistValues(result, typeSafe, []);
            if (this.isBrowser && result.failedReason == FailedReasonType.SessionAccessDenied) {
              //retry
              if (retCount < 3) {
                //macretry
                this.appSessionService.AppInitObs().subscribe(x => {
                  this.returnRetryObeservable<T>(serviceName, url, data, dateParser, typeSafe, retCount + 1).subscribe(
                    (response: any) => {
                      res.next(response),
                        err => {
                          res.error(err),
                            () => {
                              res.complete();
                            };
                        };
                    }
                  );
                });
              }
            } else if (this.isBrowser && result.failedReason == FailedReasonType.AccessDenied) {
              //retry
              if (retCount < 3) {
                //macretry
                this.appSessionService.AppInitObs().subscribe(x => {
                  if (!this.appSessionService.isAuthenticated) {
                    this.appSessionService.login_registerIsOpen = true;
                    this.appSessionService.loginCallback = () => {
                      if (this.appSessionService.isAuthenticated)
                        this.returnRetryObeservable<T>(
                          serviceName,
                          url,
                          data,
                          dateParser,
                          typeSafe,
                          retCount + 1
                        ).subscribe((response: any) => {
                          res.next(response),
                            err => {
                              res.error(err),
                                () => {
                                  res.complete();
                                };
                            };
                        });
                      else {
                        res.complete();
                      }
                    };
                  }
                });
              }
            } else {
              // of<T>(result);
              res.next(result);
              res.complete();
            }
          } catch (ex) {
            let a = 0;
            //console.log("fix reference error:", ex);
          }
        },
        error: error => {
          //console.log("error response: ", error);
          res.error(error);
          res.complete();
        },
      });
    return res.asObservable();
  }
  assignNotExistValues(objResult: any, objDefaultValues: any, mappedObjects: any) {
    var type = typeof objResult;
    if (type == 'string' || type == 'number' || objResult === Date || objResult == null || objResult == undefined) {
      return;
    }
    if (mappedObjects.indexOf(objResult) >= 0) {
      return objResult;
    }
    mappedObjects.push(objResult);

    for (var origKey in objDefaultValues) {
      if (!objResult.hasOwnProperty(origKey)) {
        objResult[origKey] = objDefaultValues[origKey];
      }
      this.assignNotExistValues(objResult[origKey], objDefaultValues[origKey], mappedObjects);
    }
  }

  toCamelCase(o: any) {
    var origKey, newKey, value;
    if (o instanceof Array) {
      return o;
    } else {
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
          if (newKey == origKey) continue;
          value = o[origKey];
          o[newKey] = value;
          delete o[origKey];
        }
      }
    }
  }

  fixDateTimeProperties(data: any, oneTimeList: any) {
    if (data == null || data == undefined || oneTimeList.find(x => x == data)) return;
    oneTimeList.push(data);

    if (Array.isArray(data)) {
      data.forEach(x => {
        this.fixDateTimeProperties(x, oneTimeList);
      });
    } else {
      let properties = Object.getOwnPropertyNames(data);
      //alert("properties: " + properties);
      properties.forEach(x => {
        let pData = data[x];
        if (pData == null || pData == undefined || oneTimeList.find(x => x == pData)) return;
        // alert(pData + " : " + (typeof Date));

        if (pData instanceof Date) {
          data[x] = new Date(
            Date.UTC(
              pData.getFullYear(),
              pData.getMonth(),
              pData.getDate(),
              pData.getHours(),
              pData.getMinutes(),
              pData.getSeconds(),
              pData.getMilliseconds()
            )
          );
        } else if (isDate(pData) == true) {
          //&& !isNaN(Date.parse(pData)
          //console.log('is date', pData);
          data[x] = new Date(pData);
        }

        this.fixDateTimeProperties(pData, oneTimeList);
      });
    }
  }

  deserializeReferences<T>(obj: any, ids: any, mappedObjects: Array<T>): any {
    var type = typeof obj;
    if (type == 'string' || type == 'number' || obj === Date || obj == null || obj == undefined) {
      return obj;
    }
    if (mappedObjects.indexOf(obj) >= 0 && !obj.$values) {
      return obj;
    }
    mappedObjects.push(obj);
    if (obj.$id) {
      if (obj.$values) ids[obj.$id] = obj.$values;
      else ids[obj.$id] = obj;
      delete obj.$id;
    }

    if (obj.$ref) {
      var ref = obj.$ref;
      obj = ids[obj.$ref];
      return obj;
    }
    if (obj instanceof Array) {
      var newArray: any[] = [];
      obj.forEach(x => {
        if (x.$ref) {
          x = ids[x.$ref];
          if (x) {
            newArray.push(x);
            delete x.$ref;
          }
        } else {
          newArray.push(this.deserializeReferences(x, ids, mappedObjects));
        }
      });
      return newArray;
    } else if (obj.$values) {
      var newArray = [];
      obj.$values.forEach((x: any) => {
        if (x && x.$ref) {
          x = ids[x.$ref];
          if (x) {
            this.toCamelCase(x);
            newArray.push(x);
            delete x.$ref;
          }
        } else {
          newArray.push(this.deserializeReferences(x, ids, mappedObjects));
        }
      });
      delete obj.$values;
      return newArray;
    } else {
      var properties = Object.getOwnPropertyNames(obj);
      for (var i = 0; i < properties.length; i++) {
        obj[properties[i]] = this.deserializeReferences(obj[properties[i]], ids, mappedObjects);
      }
    }
    this.toCamelCase(obj);
    return obj;
  }
}
@Injectable({
  providedIn: 'any',
})
export class AppinitServerConnectionService {
  private environmentServerUrl: string;
  platformId: any;
  constructor(public injector: Injector, public http: HttpClient) {
    this.platformId = injector.get(PLATFORM_ID);

    //this.environmentServerUrl = environmentServerUrl
    //if (this.isUtravs)
    {
      this.environmentServerUrl = this.getDomain;
    }
    AppConsts.remoteServiceBaseUrlSGO = this.environmentServerUrl;
  }
  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  get getDomain(): string {
    if (this.isBrowser) {
      if (location.href.toLowerCase().indexOf(':4200') > -1) return 'localhost';
    }
    return environment.environmentServerUrl;
  }

  checkUrl(serviceName) {
    let url = 'utravs.com'; // Get the domain from the URL
    let protocol = 'https:';
    let api = 'api';
    let proxy = '';
    let baseurl = '';
    if (this.isBrowser) {
      url = location.host
        .replace('agent.', '')
        .replace('agent-mata.', '')
        .replace('agent-sale.', '')
        .replace('mata.', '');
      if (url.indexOf('localhost') > -1) {
        proxy = '/' + api;
        api = '';
      }
      protocol = location.protocol;
      if (api) {
        if (url.split('.').length > 2) api = `${api}-`;
        else api = `${api}.`;
      }
      baseurl = `${protocol}//${api}${url}${proxy}${verUrls[environment.environmentServerUrl][serviceName]}`;
    } else {
      if (environment.environmentServerUrl == 'staging') api = `${api}-beta.`;
      else api = `${api}.`;
      baseurl = `${protocol}//${api}${url}${proxy}${verUrls[environment.environmentServerUrl][serviceName]}`;
    }
    return baseurl;
  }

  post<T>(url: string, data: any, typeSafe: any, serviceName?: string): Observable<T> {
    try {
      this.fixDateTimeProperties(data, []);
    } catch (ex) {
      //console.log("fix date time error:", ex);
    }

    return new Observable<T>(observer => {
      return this.http.post(this.checkUrl(serviceName) + url, data, { withCredentials: true }).subscribe({
        next: response => {
          try {
            var json = JSON.stringify(response);
            var result = JSON.parse(json, getDateParser);
            result = this.deserializeReferences(result, {}, []);
            //asine null values from server to object of client
            this.assignNotExistValues(result, typeSafe, []);
            if (result.FailedReason == FailedReasonType.SessionAccessDenied) {
              //retry
            }
            observer.next(result);

            observer.complete();
          } catch (ex) {
            //console.log("fix reference error:", ex);
          }
        },
        error: error => {
          //console.log("error response: ", error);
          observer.next(error);
        },
      });
    });
  }

  get<T>(url: string, data: any, typeSafe: any, serviceName?: string): Observable<T> {
    try {
      this.fixDateTimeProperties(data, []);
    } catch (ex) {
      //console.log("fix date time error:", ex);
    }
    let queryParamsString = '';
    if (data) {
      let params = new HttpParams({ fromObject: data });
      queryParamsString = '?' + params.toString();
    }
    return new Observable<T>(observer => {
      return this.http.get(this.checkUrl(serviceName) + url + queryParamsString).subscribe({
        next: response => {
          try {
            var json = JSON.stringify(response);
            var result = JSON.parse(json, getDateParser);
            result = this.deserializeReferences(result, {}, []);
            //asine null values from server to object of client
            this.assignNotExistValues(result, typeSafe, []);
            if (result.FailedReason == FailedReasonType.SessionAccessDenied) {
              //retry
            }
            observer.next(result);

            observer.complete();
          } catch (ex) {
            //console.log("fix reference error:", ex);
          }
        },
        error: error => {
          //console.log("error response: ", error);
          observer.next(error);
        },
      });
    });
  }

  assignNotExistValues(objResult: any, objDefaultValues: any, mappedObjects: any) {
    var type = typeof objResult;
    if (type == 'string' || type == 'number' || objResult === Date || objResult == null || objResult == undefined) {
      return;
    }
    if (mappedObjects.indexOf(objResult) >= 0) {
      return objResult;
    }
    mappedObjects.push(objResult);

    for (var origKey in objDefaultValues) {
      if (!objResult.hasOwnProperty(origKey)) {
        objResult[origKey] = objDefaultValues[origKey];
      }
      this.assignNotExistValues(objResult[origKey], objDefaultValues[origKey], mappedObjects);
    }
  }

  toCamelCase(o: any) {
    var origKey, newKey, value;
    if (o instanceof Array) {
      return o;
    } else {
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
          if (newKey == origKey) continue;
          value = o[origKey];
          o[newKey] = value;
          delete o[origKey];
        }
      }
    }
  }

  fixDateTimeProperties(data: any, oneTimeList: any) {
    if (data == null || data == undefined || oneTimeList.find(x => x == data)) return;
    oneTimeList.push(data);

    if (Array.isArray(data)) {
      data.forEach(x => {
        this.fixDateTimeProperties(x, oneTimeList);
      });
    } else {
      let properties = Object.getOwnPropertyNames(data);
      //alert("properties: " + properties);
      properties.forEach(x => {
        let pData = data[x];
        if (pData == null || pData == undefined || oneTimeList.find(x => x == pData)) return;
        // alert(pData + " : " + (typeof Date));

        if (pData instanceof Date) {
          data[x] = new Date(
            Date.UTC(
              pData.getFullYear(),
              pData.getMonth(),
              pData.getDate(),
              pData.getHours(),
              pData.getMinutes(),
              pData.getSeconds(),
              pData.getMilliseconds()
            )
          );
        } else if (isDate(pData) == true) {
          //&& !isNaN(Date.parse(pData)
          //console.log('is date', pData);
          data[x] = new Date(pData);
        }

        this.fixDateTimeProperties(pData, oneTimeList);
      });
    }
  }

  deserializeReferences<T>(obj: any, ids: any, mappedObjects: Array<T>): any {
    var type = typeof obj;
    if (type == 'string' || type == 'number' || obj === Date || obj == null || obj == undefined) {
      return obj;
    }
    if (mappedObjects.indexOf(obj) >= 0 && !obj.$values) {
      return obj;
    }
    mappedObjects.push(obj);
    if (obj.$id) {
      if (obj.$values) ids[obj.$id] = obj.$values;
      else ids[obj.$id] = obj;
      delete obj.$id;
    }

    if (obj.$ref) {
      var ref = obj.$ref;
      obj = ids[obj.$ref];
      return obj;
    }
    if (obj instanceof Array) {
      var newArray: any[] = [];
      obj.forEach(x => {
        if (x.$ref) {
          x = ids[x.$ref];
          if (x) {
            newArray.push(x);
            delete x.$ref;
          }
        } else {
          newArray.push(this.deserializeReferences(x, ids, mappedObjects));
        }
      });
      return newArray;
    } else if (obj.$values) {
      var newArray = [];
      obj.$values.forEach((x: any) => {
        if (x && x.$ref) {
          x = ids[x.$ref];
          if (x) {
            this.toCamelCase(x);
            newArray.push(x);
            delete x.$ref;
          }
        } else {
          newArray.push(this.deserializeReferences(x, ids, mappedObjects));
        }
      });
      delete obj.$values;
      return newArray;
    } else {
      var properties = Object.getOwnPropertyNames(obj);
      for (var i = 0; i < properties.length; i++) {
        obj[properties[i]] = this.deserializeReferences(obj[properties[i]], ids, mappedObjects);
      }
    }
    this.toCamelCase(obj);
    return obj;
  }
}
